import {
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useState } from 'react';

const EditButton = ({ entityType, EditComponent, initialData, onConfirm }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(initialData || {});
  const [isLoading, setIsLoading] = useState(false);

  //   console.log(formData.options);

  const handleConfirmEdit = async () => {
    setIsLoading(true);
    const finalFormData = new FormData();

    // Append other data to formData
    Object.keys(formData).forEach((key) => {
      if (key !== 'image' && key !== 'options' && key !== 'images') {
        finalFormData.append(key, formData[key]);
      }
    });

    // Check if the image field is an empty string and images contain a file
    if (formData.image === '') {
      finalFormData.append('image', ''); // Pass empty string for the image field
      if (formData.images) {
        finalFormData.append('images', formData.images); // Attach the new image file
      }
    } else if (formData.images) {
      // If there is an image to update, append it directly
      finalFormData.append('images', formData.images);
    }

    // Convert options to a JSON string and append
    finalFormData.append(
      'options',
      JSON.stringify(
        formData.options?.map((opt) => ({
          option: opt.option,
          price: opt.price,
          details: opt.details,
          image: opt.image ? opt.image : '', // Include image file if available
        })) || [],
      ),
    );

    try {
      await onConfirm(finalFormData); // Make sure your onConfirm can handle FormData
    } catch (error) {
      console.error(`Error editing ${entityType}:`, error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Tooltip label="Edit">
        <IconButton
          icon={<EditIcon boxSize={4} />}
          size="sm"
          colorScheme="blue"
          variant="ghost"
          onClick={onOpen}
          aria-label="Edit"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit {entityType}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditComponent formData={formData} setFormData={setFormData} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmEdit}
              isLoading={isLoading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditButton;
