import React, { useState } from 'react';
import {
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react';

const GenericDetailView = ({
  type, // 'text', 'image', 'name'
  content,
  title = 'Details',
  maxChars = 20,
  truncateType = 'chars',
  imageAlt = 'Image',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const truncateContent = (content) => {
    if (truncateType === 'words') {
      const words = content.split(' ');
      return words.length > maxChars
        ? words.slice(0, maxChars).join(' ') + '...'
        : content;
    }
    return content.length > maxChars
      ? content.slice(0, maxChars) + '...'
      : content;
  };

  const handleOpen = () => {
    if (
      type === 'text' &&
      (content.length > maxChars ||
        (truncateType === 'words' && content.split(' ').length > maxChars))
    ) {
      setIsOpen(true);
    } else if (type === 'image' || type === 'name') {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const renderContentPreview = () => {
    switch (type) {
      case 'text':
        return (
          <Tooltip
            label="Click to view full details"
            isDisabled={
              content.length <= maxChars &&
              (truncateType === 'chars' ||
                content.split(' ').length <= maxChars)
            }
          >
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {truncateContent(content)}
            </Text>
          </Tooltip>
        );
      case 'image':
        return (
          <Image
            boxSize="60px"
            objectFit="cover"
            src={content}
            alt={imageAlt}
            cursor="pointer"
          />
        );
      case 'name':
        return (
          <Text
            color="secondaryGray.900"
            fontSize="sm"
            fontWeight="700"
            cursor="pointer"
          >
            {content.split(' ')[0]} {/* Display the first word */}
          </Text>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Flex align="center" onClick={handleOpen} cursor="pointer">
        {renderContentPreview()}
      </Flex>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={type === 'image' ? '40%' : 'md'}>
          <ModalCloseButton />
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <Flex
              justifyContent="center"
              alignItems="center"
              height={type === 'image' ? '100%' : 'auto'}
            >
              {type === 'text' && <Text>{content}</Text>}
              {type === 'image' && (
                <Image
                  src={content}
                  alt={imageAlt}
                  maxH="90vh"
                  maxW="100%"
                  objectFit="contain"
                />
              )}
              {type === 'name' && <Text fontSize="lg">{content}</Text>}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenericDetailView;
