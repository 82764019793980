import {
  Tooltip,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useState, useRef } from 'react';

const DeleteButton = ({ entityType, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleConfirmDelete = async () => {
    setIsLoading(true);

    try {
      await onDelete(); // Call the delete function passed as a prop
    } catch (error) {
      console.error(`Error deleting ${entityType}:`, error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Tooltip label="Delete">
        <IconButton
          icon={<DeleteIcon boxSize={4} />}
          size="sm"
          colorScheme="red"
          variant="ghost"
          onClick={onOpen}
          isLoading={isLoading}
          aria-label="Delete"
        />
      </Tooltip>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this {entityType}?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmDelete}
                ml={3}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteButton;
