import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Textarea,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OfferPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const [formData, setFormData] = useState({
    offerName: '',
    offerDescription: '',
    applicableOn: '',
    offerValidOn: '',
    offerType: 'percentage',
    offerValue: '',
    offerValidity: new Date(), // Default to current date
  });

  const [errors, setErrors] = useState({
    offerName: '',
    offerDescription: '',
    applicableOn: '',
    offerValidOn: '',
    offerType: '',
    offerValue: '',
    offerValidity: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else if (name === 'offerValue' && isNaN(value)) {
      setErrors((prev) => ({
        ...prev,
        [name]: `${name} must be a numeric value`,
      }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, offerValidity: date }));
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      // Adjust offerValue if offerType is product
      const adjustedFormData = {
        ...formData,
        offerValue:
          formData.offerType === 'product'
            ? Math.max(0, Number(formData.offerValue) - 1)
            : formData.offerValue,
        offerValidity: formatDate(formData.offerValidity),
      };

      try {
        await onConfirm(adjustedFormData);
        onClose();
      } catch (error) {
        console.error('Error adding offer:', error);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.applicableOn.trim())
      newErrors.applicableOn = 'Applicable On is required';
    if (!formData.offerValidOn.trim())
      newErrors.offerValidOn = 'Offer Valid On is required';
    if (!formData.offerValue.trim() || isNaN(formData.offerValue))
      newErrors.offerValue = 'Offer Value must be a numeric value';
    if (!formData.offerValidity)
      newErrors.offerValidity = 'Offer Validity date is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Offer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Offer Name</FormLabel>
            <Input
              name="offerName"
              value={formData.offerName}
              onChange={handleInputChange}
              placeholder="Enter offer name"
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Offer Description</FormLabel>
            <Textarea
              name="offerDescription"
              value={formData.offerDescription}
              onChange={handleInputChange}
              placeholder="Enter offer description"
            />
          </FormControl>

          <FormControl isInvalid={!!errors.applicableOn} mb={3}>
            <FormLabel>Applicable On</FormLabel>
            <Select
              name="applicableOn"
              value={formData.applicableOn}
              onChange={handleInputChange}
            >
              <option value="wallet_booking">Wallet Booking</option>
              <option value="package_booking">Package Booking</option>
              <option value="credit_card">Credit Card</option>
              <option value="debit_card">Debit Card</option>
              <option value="upi">UPI</option>
            </Select>
            <FormErrorMessage>{errors.applicableOn}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerValidOn} mb={3}>
            <FormLabel>Offer Valid On (Numeric Value)</FormLabel>
            <Input
              name="offerValidOn"
              type="number"
              value={formData.offerValidOn}
              onChange={handleInputChange}
              placeholder="Enter applicable amount"
            />
            <FormErrorMessage>{errors.offerValidOn}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerType} mb={3}>
            <FormLabel>Offer Type</FormLabel>
            <Select
              name="offerType"
              value={formData.offerType}
              onChange={handleInputChange}
            >
              <option value="percentage">Percentage</option>
              <option value="amount">Amount</option>
              <option value="product">Product</option>
            </Select>
            <FormErrorMessage>{errors.offerType}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerValue} mb={3}>
            <FormLabel>Offer Value</FormLabel>
            <Input
              name="offerValue"
              type="number"
              value={formData.offerValue}
              onChange={handleInputChange}
              placeholder="Enter offer value"
            />
            <FormErrorMessage>{errors.offerValue}</FormErrorMessage>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Offer Validity Date</FormLabel>
            <DatePicker
              selected={formData.offerValidity}
              onChange={handleDateChange}
              minDate={new Date()}
              dateFormat="dd/MM/yyyy"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Add Offer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OfferPopup;
