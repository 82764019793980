import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';

const AddSlotPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const [formData, setFormData] = useState({
    startTime: '',
    clockCycle: 'AM',
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.startTime.trim())
      newErrors.startTime = 'Start Time is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        await onConfirm(formData);
        onClose();
      } catch (error) {
        console.error('Error adding slot:', error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Slot</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!errors.startTime} mb={3}>
            <FormLabel>Start Time</FormLabel>
            <Input
              name="startTime"
              type="time"
              value={formData.startTime}
              onChange={handleInputChange}
              placeholder="Enter start time"
            />
            <FormErrorMessage>{errors.startTime}</FormErrorMessage>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Clock Cycle</FormLabel>
            <Select
              name="clockCycle"
              value={formData.clockCycle}
              onChange={handleInputChange}
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            colorScheme="blue"
          >
            Add Slot
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSlotPopup;
