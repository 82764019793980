import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const CreateAdmin = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    roles: [], // Array to handle multiple role selection
    password: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    username: '',
    email: '',
    roles: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleRoleChange = (selectedRoles) => {
    setFormData((prev) => ({ ...prev, roles: selectedRoles }));
    if (selectedRoles.length === 0) {
      setErrors((prev) => ({
        ...prev,
        roles: 'At least one role is required',
      }));
    } else {
      setErrors((prev) => ({ ...prev, roles: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.username.trim()) newErrors.username = 'Username is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (formData.roles.length === 0) newErrors.roles = 'Role is required';
    if (!formData.password.trim()) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const dataToSubmit = {
          ...formData,
          role: formData.roles.join(','), // Convert array of roles to a comma-separated string
        };
        await onConfirm(dataToSubmit);
        onClose();
      } catch (error) {
        console.error('Error adding admin:', error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Admin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={errors.name} mb={3}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter name"
            />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.username} mb={3}>
            <FormLabel>Username</FormLabel>
            <Input
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              placeholder="Enter username"
            />
            <FormErrorMessage>{errors.username}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.email} mb={3}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter email"
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.roles} mb={3}>
            <FormLabel>Role</FormLabel>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Select Roles
              </MenuButton>
              <MenuList height="150px" overflowY="scroll">
                <CheckboxGroup
                  value={formData.roles}
                  onChange={handleRoleChange}
                >
                  <Stack direction="column" p={2}>
                    <Checkbox value="customer_manager">
                      Customer Manager
                    </Checkbox>
                    <Checkbox value="partner_manager">Partner Manager</Checkbox>
                    <Checkbox value="stock_manager">Stock Manager</Checkbox>
                    <Checkbox value="category_manager">
                      Category Manager
                    </Checkbox>
                    <Checkbox value="banner_manager">Banner Manager</Checkbox>
                    <Checkbox value="product_manager">Product Manager</Checkbox>
                    <Checkbox value="membership_manager">
                      Membership Manager
                    </Checkbox>
                    <Checkbox value="feedback_manager">
                      Feedback Manager
                    </Checkbox>
                    <Checkbox value="complaint_manager">
                      Complaint Manager
                    </Checkbox>
                    <Checkbox value="notification_manager">
                      Notification Manager
                    </Checkbox>
                    <Checkbox value="offer_manager">Offer Manager</Checkbox>
                    <Checkbox value="slot_manager">Slot Manager</Checkbox>
                    <Checkbox value="booking_manager">Booking Manager</Checkbox>
                  </Stack>
                </CheckboxGroup>
              </MenuList>
            </Menu>
            <FormErrorMessage>{errors.roles}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password} mb={3}>
            <FormLabel>Password</FormLabel>
            <Input
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter password"
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Add Admin
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateAdmin;
