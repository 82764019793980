import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Flex,
  Image,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';

const ProductPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    brand: '',
    size: '',
    sizeUnit: 'ml',
    currentStock: '',
    mrp: '',
    purchasingRate: '',
    barcodeNumber: '',
    image: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    brand: '',
    size: '',
    currentStock: '',
    mrp: '',
    purchasingRate: '',
    barcodeNumber: '',
    image: '',
  });

  const updateSize = (size, sizeUnit) => {
    setFormData((prev) => ({
      ...prev,
      size: `${size} ${sizeUnit}`,
    }));
  };

  const handleSizeUnitChange = (e) => {
    const newUnit = e.target.value;
    const size = formData.size.split(' ')[0]; // Extract the numeric part
    updateSize(size, newUnit);
    setFormData((prev) => ({ ...prev, sizeUnit: newUnit }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === 'size') {
      updateSize(value, formData.sizeUnit);
    }

    if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    // if (!formData.brand.trim()) newErrors.brand = 'Brand is required';
    if (!formData.size.trim()) newErrors.size = 'Size is required';
    if (!formData.currentStock.trim())
      newErrors.currentStock = 'Quantity is required';
    // if (!formData.mrp.trim()) newErrors.mrp = 'MRP is required';
    // if (!formData.purchasingRate.trim())
    //   newErrors.purchasingRate = 'Purchasing rate is required';
    // if (!formData.barcodeNumber.trim())
    //   newErrors.barcodeNumber = 'Barcode Number is required';
    // if (!formData.image) newErrors.image = 'Image is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const finalFormData = new FormData();
      for (const key in formData) {
        finalFormData.append(key, formData[key]);
      }
      try {
        await onConfirm(formData);
        onClose();
      } catch (error) {
        console.error('Error adding banner:', error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={errors.name} mb={3}>
            <FormLabel>Product Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter product name"
            />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Product Brand</FormLabel>
            <Input
              name="brand"
              value={formData.brand}
              onChange={handleInputChange}
              placeholder="Enter product brand"
            />
            {/* <FormErrorMessage>{errors.brand}</FormErrorMessage> */}
          </FormControl>
          <FormControl isInvalid={errors.size} mb={3}>
            <FormLabel>Product Size</FormLabel>
            <Flex align="center" gap={2}>
              <Input
                name="size"
                value={formData.size.split(' ')[0]} // Show only the numeric part
                onChange={handleInputChange}
                placeholder="Enter product size"
              />
              <Select
                name="sizeUnit"
                value={formData.sizeUnit}
                onChange={handleSizeUnitChange}
              >
                <option value="ml">ml</option>
                <option value="pc">pc</option>
                <option value="gms">gms</option>
                <option value="kg">kg</option>
                <option value="time use">time use</option>
              </Select>
              <FormErrorMessage>{errors.size}</FormErrorMessage>
            </Flex>
          </FormControl>
          <FormControl isInvalid={errors.currentStock} mb={3}>
            <FormLabel>Quantity</FormLabel>
            <Input
              name="currentStock"
              value={formData.currentStock}
              onChange={handleInputChange}
              placeholder="Enter current stock"
            />
            <FormErrorMessage>{errors.currentStock}</FormErrorMessage>
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Product MRP</FormLabel>
            <Input
              name="mrp"
              value={formData.mrp}
              onChange={handleInputChange}
              placeholder="Enter product MRP"
            />
            {/* <FormErrorMessage>{errors.mrp}</FormErrorMessage> */}
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Purchasing Rate</FormLabel>
            <Input
              name="purchasingRate"
              value={formData.purchasingRate}
              onChange={handleInputChange}
              placeholder="Enter purchasing rate"
            />
            {/* <FormErrorMessage>{errors.purchasingRate}</FormErrorMessage> */}
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Barcode Number</FormLabel>
            <Input
              name="barcodeNumber"
              value={formData.barcodeNumber}
              onChange={handleInputChange}
              placeholder="Enter barcode number"
            />
            {/* <FormErrorMessage>{errors.barcodeNumber}</FormErrorMessage> */}
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Upload Image</FormLabel>
            <Input
              name="image"
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setErrors, setFormData, setImagePreview)
              }
            />
            {/* <FormErrorMessage>{errors.image}</FormErrorMessage> */}
          </FormControl>
          {imagePreview && (
            <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Add Product
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductPopup;
