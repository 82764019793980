import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AdminLayout from './layouts/admin';
import AdminLogin from './pages/admin/index';

import {
  ChakraProvider,
  Flex,
  Spinner,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { AuthProvider } from 'contexts/AuthContext';
import { SearchProvider } from 'contexts/SearchContext';
// Chakra imports

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Flex align="center" justify="center" h="60vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
        />
      </Flex>
    );
  }

  if (!user) {
    return <Navigate to="/admin/sign-in" replace />;
  }

  return children;
};

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <AuthProvider>
        <SearchProvider>
          <Routes>
            <Route
              path="admin/*"
              element={
                <ProtectedRoute>
                  <AdminLayout
                    theme={currentTheme}
                    setTheme={setCurrentTheme}
                  />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Navigate to="/admin" replace />} />
            <Route path="/admin/sign-in" element={<AdminLogin />} />
          </Routes>
        </SearchProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

// const roleBasedRoutes = {
//   admin_manager: '/admin/admin-table',
//   customer_manager: '/admin/customer',
//   partner_manager: '/admin/partner',
//   stock_manager: '/admin/stock',
//   category_manager: '/admin/category',
//   banner_manager: '/admin/banner',
//   product_manager: '/admin/products',
//   membership_manager: '/admin/membership',
//   feedback_manager: '/admin/feedback',
//   complaint_manager: '/admin/complaints',
//   notification_manager: '/admin/notification',
//   offer_manager: '/admin/offers',
//   slot_manager: '/admin/slots',
//   booking_manager: '/admin/booking',
// };

// const ProtectedRoute = ({ children, allowedRoles }) => {
//   const { user, roles, loading } = useAuth();

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!user) {
//     return <Navigate to="/admin/sign-in" replace />;
//   }

//   // Ensure roles is an array
//   let userRoles = [];

//   if (typeof roles === 'string') {
//     userRoles = roles.split(',').map((role) => role.trim()); // Make sure to trim any whitespace
//   } else if (Array.isArray(roles)) {
//     userRoles = roles.map((role) => role.trim()); // Trim if roles is an array
//   }

//   // Debugging logs
//   console.log('User Roles:', userRoles);
//   console.log('Allowed Roles:', allowedRoles);

//   // If 'all' is in allowedRoles, check if the user has 'all' role
//   if (allowedRoles.includes('all')) {
//     if (userRoles.includes('all')) {
//       return children;
//     } else {
//       // Redirect to a default route based on user roles
//       const defaultRoute = userRoles.find((role) => roleBasedRoutes[role]);
//       console.log(defaultRoute);

//       if (defaultRoute) {
//         return <Navigate to={roleBasedRoutes[defaultRoute]} replace />;
//       }
//       // If no valid role, redirect to sign-in
//       return <Navigate to="/admin/sign-in" replace />;
//     }
//   }

//   // Check if the user has any of the allowed roles
//   const matchingRole = userRoles.find((role) => {
//     console.log('Checking role:', role);
//     return allowedRoles.includes(role);
//   });

//   console.log('Matching Role:', matchingRole);

//   if (matchingRole) {
//     return children;
//   }

//   // If no valid role, redirect to sign-in
//   return <Navigate to="/admin/sign-in" replace />;
// };
