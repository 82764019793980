import { useState } from 'react';

const usePagination = (totalPages, initialPage = 1) => {
  const [currentPage, setCurrentPage] = useState(initialPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  // console.log(currentPage);

  return { currentPage, handlePageChange, setCurrentPage };
};

export default usePagination;
