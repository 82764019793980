// Chakra imports
import React from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import AddNewNotification from './components/AddNotification';
import DevelopmentTable from 'views/admin/notificationTable/components/NotificationTable';

export default function Settings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" color="white" h="100%" gap="4">
        <AddNewNotification onOpen={onOpen} />
        <DevelopmentTable isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Box>
  );
}
