import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Image,
  Text,
  Stack,
} from '@chakra-ui/react';

const OptionsColumn = ({ options, textColor = 'black' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} colorScheme="blue" variant="solid">
        See options
      </Button>

      {/* Modal for displaying options */}
      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Options Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {options.map((option) => (
                <Flex
                  key={option._id}
                  border="1px solid #e2e8f0"
                  borderRadius="md"
                  p={4}
                  alignItems="center"
                  justifyContent="space-between"
                  bg="gray.50"
                >
                  <Box flex="1">
                    <Text fontWeight="bold" color={textColor}>
                      {option.option}
                    </Text>
                    <Text mt={2} color="gray.600">
                      {option.details}
                    </Text>
                  </Box>
                  <Flex flex="1" alignItems="center" justifyContent="center">
                    <Image
                      src={option.image}
                      boxSize="50px"
                      objectFit="cover"
                      borderRadius="md"
                      alt={option.option}
                    />
                  </Flex>
                  <Box flex="1" textAlign="right">
                    <Text fontSize="lg" fontWeight="bold" color="green.500">
                      ₹ {option.price}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OptionsColumn;
