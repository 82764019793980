// Chakra imports
import React from 'react';
import AddNewProduct from './components/AddProduct';
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import DevelopmentTable from 'views/admin/productTable/components/ProductTable';
import { DownloadIcon } from '@chakra-ui/icons';
import useCategoryProductActions from 'hooks/categoryProductApis/useCategoryProductActions';

export default function Settings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { downloadExcel } = useCategoryProductActions();
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" color="white" h="100%" gap="4">
        <Flex justifyContent="flex-end" gap="4">
          <AddNewProduct onOpen={onOpen} />
          <IconButton
            aria-label="Download Product Data"
            icon={<DownloadIcon />}
            colorScheme="blue"
            onClick={downloadExcel}
          />
        </Flex>
        <DevelopmentTable isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Box>
  );
}
