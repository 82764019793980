// Chakra imports
import React from 'react';
import AddStock from './components/AddStock';
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import DevelopmentTable from 'views/admin/stocksTable/components/StocksTable';
import { DownloadIcon } from '@chakra-ui/icons';
import useStockActions from 'hooks/stockApis/useStockActions';

export default function Settings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { downloadExcel } = useStockActions();
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" color="white" h="100%" gap="4">
        <Flex justifyContent="flex-end" gap="4">
          <AddStock onOpen={onOpen} />
          <IconButton
            aria-label="Download Partner Data"
            icon={<DownloadIcon />}
            colorScheme="blue"
            onClick={downloadExcel}
          />
        </Flex>
        <DevelopmentTable isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Box>
  );
}
