import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';

const useAdmin = () => {
  const [admins, setAdmins] = useState({ data: [], totalPages: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast({ position: 'top' });
  const { token, user } = useAuth();

  const fetchAllAdmins = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/admin/all?page=${page}&adminId=${user.id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${token}`,
            },
            validateStatus: function (status) {
              return status >= 200 && status < 500;
            },
          },
        );

        if (response.data.success) {
          // console.log('Admins fetched successfully:', response.data);
          setAdmins({
            data: response.data.data,
            totalPages: response.data.totalPages,
            currentPage: page,
          });
        } else {
          // console.error('Failed to fetch admins:', response.data);
          setError(response.data.message || 'Failed to fetch admins');
        }
      } catch (error) {
        // console.error('Error fetching admins:', error);
        setError(
          'An unexpected error occurred while fetching admins. Please try again.',
        );
      } finally {
        setIsLoading(false);
      }
    },
    [token],
  );

  const registerAdmin = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/register?adminId=${user.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Success',
          description: 'New admin added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new admin');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new admin',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      // console.error('Error adding admin:', error);
      setError('An error occurred while adding the admin. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the admin. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchAllAdmins,
    admins,
    registerAdmin,
    isLoading,
    error,
  };
};

export default useAdmin;
