// chakra imports
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { useAuth } from 'contexts/AuthContext';
import React from 'react';

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const { logout } = useAuth();

  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      // pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px" overflow="auto">
        <Box ps="20px" pe={{ md: '16px', '2xl': '1px' }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box mt="30px" mb="40px" px="20px" borderRadius="30px">
        <Button
          color="red.400"
          variant="no-hover"
          bg="transparent"
          p="0px"
          h="18px"
          size="md"
          height="46px"
          width="100%"
          border="2px"
          borderColor="red.500"
          onClick={logout}
        >
          <Text fontSize="sm">Log out</Text>
        </Button>
        {/* <MenuItem
          _hover={{ bg: 'none' }}
          _focus={{ bg: 'none' }}
          color="red.400"
          borderRadius="8px"
          px="14px"
          onClick={logout}
        >
          <Text fontSize="sm">Log out</Text>
        </MenuItem> */}
      </Box>
    </Flex>
  );
}

export default SidebarContent;
