import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const useStock = () => {
  const [error, setError] = useState(null);
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [registeredStock, setRegisteredStock] = useState(null);
  const toast = useToast({ position: 'top' });

  const fetchStocks = useCallback(async (page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/stock/all?page=${page}`,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );
      // console.log(response);

      if (response.data.success) {
        setStocks({
          data: response.data.data,
          totalPages: response.data.totalPages,
          currentPage: page,
        });
      } else {
        setError(response.data.message || 'Failed to fetch stocks.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const registerStock = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/stock/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const newStock = response.data.data;
        setRegisteredStock(newStock);

        toast({
          title: 'Success',
          description:
            response.data.message || 'New product added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new product');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new product',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError('An error occurred while adding the product. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the product. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredStock,
    registerStock,
    fetchStocks,
    isLoading,
    stocks,
    error,
  };
};

export default useStock;
