import React from 'react';

// Chakra imports
import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import HorizonLogo from 'assets/img/Doorstep Beauty.png';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex align="center" direction="column">
      <Flex align="center">
        <Image
          borderRadius="full"
          boxSize="80px"
          src={HorizonLogo}
          alt="Horizon Logo"
          mixBlendMode="multiply"
        />
        <Text
          fontSize="1.2rem"
          my="32px"
          color={logoColor}
          fontWeight={700}
          display="flex"
          gap={2}
        >
          DOORSTEP <Text fontWeight={400}>BEAUTY</Text>
        </Text>
      </Flex>
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
