// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import routes from 'routes.js';
import { useAuth } from 'contexts/AuthContext'; // Assuming you have a context for auth

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { roles } = useAuth();
  const { onOpen } = useDisclosure();
  const location = useLocation();
  const pathname = location.pathname;

  const getActiveRoute = (routes, pathname) => {
    for (const route of routes) {
      if (route.collapse) {
        const collapseActiveRoute = getActiveRoute(route.items, pathname);
        if (collapseActiveRoute) return collapseActiveRoute;
      } else if (route.category) {
        const categoryActiveRoute = getActiveRoute(route.items, pathname);
        if (categoryActiveRoute) return categoryActiveRoute;
      } else if (pathname.includes(route.layout + route.path)) {
        return route.name;
      }
    }
    return '';
  };

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      const isRoleAllowed = route.roles
        ? route.roles.some((role) => roles.includes(role))
        : false;
      if (
        route.layout === '/admin' &&
        (isRoleAllowed || roles.includes('all'))
      ) {
        return <Route path={route.path} element={route.component} key={key} />;
      }
      if (route.collapse) return getRoutes(route.items);
      return null;
    });
  };

  const redirectToFirstAccessibleRoute = () => {
    const firstAccessibleRoute = routes.find((route) =>
      route.roles?.some((role) => roles.includes(role)),
    );
    if (pathname === '/admin' && firstAccessibleRoute) {
      window.location.replace(`/admin${firstAccessibleRoute.path}`);
    }
  };

  useEffect(() => {
    redirectToFirstAccessibleRoute();
  }, [pathname, roles]);

  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Navbar
                onOpen={onOpen}
                logoText={'Brand'}
                brandText={getActiveRoute(routes, pathname)}
                secondary={getActiveRoute(routes, pathname)}
                fixed={fixed}
                {...rest}
              />
            </Portal>
            {props.children}
            <Box
              mx="auto"
              p={{ base: '20px', md: '25px' }}
              pe="20px"
              minH="93vh"
              pt="50px"
            >
              <Routes>{getRoutes(routes)}</Routes>
            </Box>
            <Box>
              <Footer />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
