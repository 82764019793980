import React, { useState, useEffect } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Image,
  FormErrorMessage,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';

const EditBannerDetails = ({ formData, setFormData }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (formData.image && typeof formData.image === 'string') {
      setImagePreview(formData.image);
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Redirect Url</FormLabel>
        <Input
          name="redirectUrl"
          value={formData.redirectUrl}
          onChange={handleInputChange}
          placeholder="Enter RedirectUrl"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Position</FormLabel>
        <Input
          name="position"
          type="tel"
          value={formData.position}
          onChange={handleInputChange}
          placeholder="Enter position"
        />
      </FormControl>

      <FormControl isInvalid={!!errors.image} mb={3}>
        <FormLabel>Upload Image</FormLabel>
        <Input
          name="image"
          type="file"
          accept="image/*"
          onChange={(e) =>
            handleImageUpload(e, setErrors, setFormData, setImagePreview)
          }
        />
        <FormErrorMessage>{errors.image}</FormErrorMessage>
        {imagePreview && (
          <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
        )}
      </FormControl>
    </>
  );
};

export default EditBannerDetails;
