import { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';

const useNotificationActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({ position: 'top' });
  const { token } = useAuth();

  const updateNotificationStatus = async (notificationId, newStatus) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/notification/change-status/?id=${notificationId}`,
        { isActive: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Status Updated',
          description:
            response.data.message ||
            'Notification status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update notification status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update notification status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteNotification = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/notification/delete/?id=${id}`,
        { isDeleted: true },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      // console.log(response.data);

      if (response.data.success) {
        toast({
          title: 'Notification Deleted',
          description:
            response.data.message ||
            'Notification has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          // title:
          //   'Account is already suspended, kindly contact the support team',
          description:
            response.data.message ||
            'Notification has been deleted successfully.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting notification:', error);
      toast({
        title: 'Delete Failed',
        description: error.message || 'Failed to delete notification.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const updateNotificationDetails = async (notificationId, updatedData) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/notification/update/?id=${notificationId}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Notification Updated',
          description:
            response.data.message ||
            'Notification details have been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update notification details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating notification:', error);
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update notification details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateNotificationStatus,
    deleteNotification,
    updateNotificationDetails,
    isLoading,
  };
};

export default useNotificationActions;
