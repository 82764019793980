import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  Flex,
  Text,
  Image,
  VStack,
  HStack,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { handleImageUpload } from 'utils/handleImageUpload';

const CategoryProductPopup = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  categories = [],
}) => {
  const category = categories.data;

  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    images: [],
    price: '',
    duration: '',
    durationUnit: 'min',
    discountPercentage: '',
    details: '',
    categoryId: '',
    options: [{ option: '', price: '', details: '' }],
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorMsg = '';
    if (name === 'price' && (isNaN(value) || value.trim() === '')) {
      errorMsg = 'Price must be a valid number';
    } else if (!value.trim()) {
      errorMsg = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }
    setErrors((prev) => ({ ...prev, [name]: errorMsg }));
  };

  // Handle option changes
  const handleOptionChange = (index, field, value) => {
    setFormData((prev) => {
      const newOptions = [...prev.options];
      newOptions[index] = { ...newOptions[index], [field]: value };
      return { ...prev, options: newOptions };
    });
  };

  // Add new option field
  const addOption = () => {
    setFormData((prev) => ({
      ...prev,
      options: [...prev.options, { option: '', price: '', details: '' }],
    }));
  };

  // Remove option field
  const removeOption = (index) => {
    setFormData((prev) => ({
      ...prev,
      options: prev.options.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    [
      'name',
      'price',
      'duration',
      'discountPercentage',
      'details',
      'categoryId',
    ].forEach((field) => {
      if (!formData[field].trim())
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle submit logic
  const handleSubmit = async () => {
    if (validateForm()) {
      const finalFormData = new FormData();

      // Append images array (each file individually)
      formData.images.forEach((file) => {
        finalFormData.append('images', file);
      });

      // Append all other fields dynamically, except 'options'
      for (const key in formData) {
        if (key !== 'options' && key !== 'images' && key !== 'image') {
          // Handle 'duration' field as a combination of value and unit
          if (key === 'duration') {
            finalFormData.append(
              'duration',
              `${formData.duration} ${formData.durationUnit}`,
            );
          } else {
            finalFormData.append(key, formData[key]);
          }
        }
      }

      // Append 'options' as a JSON string
      finalFormData.append(
        'options',
        JSON.stringify(
          formData.options.map((opt) => ({
            option: opt.option,
            price: opt.price,
            details: opt.details,
            image: opt.image || '', // Ensure image is included or set as empty string
          })),
        ),
      );

      try {
        await onConfirm(finalFormData); // Call the API
        onClose(); // Close the modal or clear the form
      } catch (error) {
        console.error('Error adding product:', error);
      }
    }
  };

  const handleImageChange = async (event, optionIndex = null) => {
    try {
      await handleImageUpload(
        event,
        (prevErrors) => setErrors(prevErrors),
        (prevData) => setFormData(prevData),
        (preview) => {
          if (optionIndex !== null) {
            handleOptionChange(optionIndex, 'imagePreview', preview);
          } else {
            setImagePreview(preview); // Otherwise, update the main image preview
          }
        },
      );

      // After the upload, handle storing the file based on optionIndex
      const file = event.target.files[0];
      if (file) {
        if (optionIndex !== null) {
          handleOptionChange(optionIndex, 'image', file);
          setFormData((prev) => ({
            ...prev,
            images: [...prev.images, file], // Append the uploaded image to the list
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            image: file,
            images: [...prevData.images, file], // Append the uploaded image to the list
          }));
        }
      }

      // Clear any error for this image field
      setErrors((prevErrors) => ({
        ...prevErrors,
        [optionIndex !== null ? `option-image-${optionIndex}` : 'image']: '',
      }));
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter name"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.price}>
              <FormLabel>Price</FormLabel>
              <Input
                name="price"
                type="number"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Enter price"
              />
              <FormErrorMessage>{errors.price}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.duration}>
              <FormLabel>Duration</FormLabel>
              <Flex>
                <Input
                  name="duration"
                  type="number"
                  value={formData.duration}
                  onChange={handleInputChange}
                  placeholder="Enter duration"
                  mr={2}
                />
                <Select
                  name="durationUnit"
                  value={formData.durationUnit}
                  onChange={handleInputChange}
                  width="100px"
                >
                  <option value="min">min.</option>
                  <option value="hr">hr.</option>
                </Select>
              </Flex>
              <FormErrorMessage>{errors.duration}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.discountPercentage}>
              <FormLabel>Discount Percentage</FormLabel>
              <Input
                name="discountPercentage"
                type="number"
                value={formData.discountPercentage}
                onChange={handleInputChange}
                placeholder="Enter discount percentage"
              />
              <FormErrorMessage>{errors.discountPercentage}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.details}>
              <FormLabel>Details</FormLabel>
              <Textarea
                name="details"
                value={formData.details}
                onChange={handleInputChange}
                placeholder="Enter details"
              />
              <FormErrorMessage>{errors.details}</FormErrorMessage>
            </FormControl>

            {category?.length > 0 ? (
              <FormControl isInvalid={!!errors.categoryId}>
                <FormLabel>Category</FormLabel>
                <Select
                  name="categoryId"
                  value={formData.categoryId}
                  onChange={handleInputChange}
                  placeholder="Select category"
                >
                  {category.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.categoryId}</FormErrorMessage>
              </FormControl>
            ) : (
              <Text>No categories available.</Text>
            )}

            <FormControl isInvalid={!!errors.image}>
              <FormLabel>Image</FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <FormErrorMessage>{errors.image}</FormErrorMessage>
            </FormControl>
            {imagePreview && (
              <Image
                src={imagePreview}
                alt="Product Preview"
                maxWidth="150px"
              />
            )}

            <Box width="100%">
              <Text fontSize="lg" mb={2}>
                Options
              </Text>
              {formData.options.map((option, index) => (
                <Box key={index} mb={4}>
                  <HStack spacing={4}>
                    <VStack spacing={2} flex={1}>
                      <FormControl>
                        <FormLabel>Option Name</FormLabel>
                        <Input
                          value={option.option}
                          onChange={(e) =>
                            handleOptionChange(index, 'option', e.target.value)
                          }
                          placeholder="Option name"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Option Price</FormLabel>
                        <Input
                          value={option.price}
                          onChange={(e) =>
                            handleOptionChange(index, 'price', e.target.value)
                          }
                          placeholder="Option price"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Option Details</FormLabel>
                        <Textarea
                          value={option.details}
                          onChange={(e) =>
                            handleOptionChange(index, 'details', e.target.value)
                          }
                          placeholder="Option details"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Option Image</FormLabel>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageChange(e, index)}
                        />
                      </FormControl>

                      {option.imagePreview && (
                        <Image
                          src={option.imagePreview}
                          alt={`Option ${index + 1} Preview`}
                          maxWidth="100px"
                          mt={2}
                        />
                      )}
                    </VStack>
                    <IconButton
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => removeOption(index)}
                      aria-label={`Delete Option ${index + 1}`}
                    />
                  </HStack>
                </Box>
              ))}
              <Button onClick={addOption} colorScheme="blue">
                Add Option
              </Button>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={isLoading}
            onClick={handleSubmit}
            ml={3}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CategoryProductPopup;
