'use client';
/* eslint-disable */

import {
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import { useAuth } from 'contexts/AuthContext';
import useAdmin from 'hooks/adminApis/useAdmin';
import usePagination from 'hooks/usePagination';
import convertDateFormat from 'utils/extractDate';
import useAdminActions from 'hooks/adminApis/useAdminActions';
import CreateAdmin from 'components/popups/adminPopup/AdminPopup';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import { useSearch } from 'contexts/SearchContext';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const { token } = useAuth();
  const toast = useToast({ position: 'top' });
  const { admins, error, fetchAllAdmins, registerAdmin } = useAdmin();
  const { updateAdminStatus, deleteAdmin, isLoading } = useAdminActions();
  const { searchResults, searchQuery } = useSearch();
  // console.log(searchResults);

  const { currentPage, handlePageChange } = usePagination(
    searchQuery ? searchResults.totalPages || 1 : admins?.totalPages || 1,
  );

  React.useEffect(() => {
    if (token && !searchQuery) fetchAllAdmins(currentPage);
  }, [token, currentPage, fetchAllAdmins]);

  const handleAddAdmin = async (formData) => {
    try {
      await registerAdmin(formData);
      await fetchAllAdmins(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: admins.message || 'Failed to add admin.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleStatusChange = (id, newStatus) => {
    try {
      updateAdminStatus(id, newStatus);
    } catch (error) {
      toast({
        title: 'Delete Failed',
        description: admins.message || 'Failed to delete admin.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteAdmin(id);
      fetchAllAdmins(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: admins.message || 'Failed to delete admin.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return admins.data || [];
  }, [admins.data, searchQuery, searchResults.data]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('username', {
      id: 'username',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          USERNAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ADDED ON
        </Text>
      ),
      cell: (info) => {
        const originalDate = info.getValue();
        const formattedDate = convertDateFormat(originalDate);
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formattedDate || 'NA'}
          </Text>
        );
      },
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="admin"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
            onStatusChange={handleStatusChange}
            fetchAllAdmins={fetchAllAdmins}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          {row.original.isDeleted ? (
            <Text color="gray.400" fontStyle="italic">
              Deleted
            </Text>
          ) : (
            <>
              <DeleteButton
                entityType="admin"
                entityId={row.original._id}
                onDelete={() => handleDelete(row.original._id)}
                isLoading={isLoading}
              />
            </>
          )}
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading)
    return (
      <Flex align="center" justify="center" h="60vh">
        <Spinner size="lg" speed="0.65s" color="blue.500" />
      </Flex>
    );

  if (error)
    return toast({
      title: 'Error',
      description: admins.message || 'Failed to fetch admins',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <CreateAdmin
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddAdmin}
          isLoading={isLoading}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={admins?.totalPages || 1}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
