import React, { useEffect, useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Flex,
  Select,
  Image,
  Button,
  VStack,
  HStack,
  FormErrorMessage,
} from '@chakra-ui/react';
// import { handleImageUpload } from 'utils/handleImageUpload';
import { handleProductImageUpload } from 'utils/handleImageUpload';

const EditCategoryProductDetails = ({ formData, setFormData }) => {
  const [durationValue, setDurationValue] = useState('');
  const [durationUnit, setDurationUnit] = useState('min.');
  const [imagePreview, setImagePreview] = useState('');
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (formData.duration) {
      const [value, unit] = formData.duration.split(' ');
      setDurationValue(value || '');
      setDurationUnit(unit || 'min.');
    }

    if (formData.image && typeof formData.image === 'string') {
      setImagePreview(formData.image);
    } else {
      setFormData((prevData) => ({ ...prevData, image: '' }));
    }

    setOptions(
      formData.options || [{ option: '', price: '', details: '', image: null }],
    );
  }, [formData, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    if (name === 'durationValue') {
      setDurationValue(value);
      setFormData((prevData) => ({
        ...prevData,
        duration: `${value} ${durationUnit}`.trim(),
      }));
    } else if (name === 'durationUnit') {
      setDurationUnit(value);
      setFormData((prevData) => ({
        ...prevData,
        duration: `${durationValue} ${value}`.trim(),
      }));
    }
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;

    // Ensure image field is correctly set
    newOptions[index].image = newOptions[index].image || null;

    setOptions(newOptions);
    setFormData((prevData) => ({ ...prevData, options: newOptions }));
  };

  const addOption = () => {
    setOptions([
      ...options,
      { option: '', price: '', details: '', image: null },
    ]);
  };

  const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    setFormData((prevData) => ({ ...prevData, options: newOptions }));
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    // Clear the image field in formData before handling the new file
    setFormData((prevData) => ({ ...prevData, image: '' }));

    // Validation: Check if file exists and is an image
    if (file) {
      const isValidImage = file.type.startsWith('image/');

      if (!isValidImage) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: 'Please upload a valid image file.',
        }));
        return;
      }

      // Clear previous error message
      setErrors((prevErrors) => ({ ...prevErrors, image: '' }));

      // Read the file for preview
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result); // Set the preview for the image
      };
      reader.readAsDataURL(file); // Read the image file

      // Call the handleUploadImage function to compress and set form data
      try {
        await handleProductImageUpload(
          event,
          setErrors,
          setFormData,
          setImagePreview,
        );
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  // const handleImageUpload = async (event) => {
  //   const file = event.target.files[0];

  //   // Clear the image field in formData before handling the new file
  //   setFormData((prevData) => ({ ...prevData, image: '' }));

  //   // Validation
  //   if (file) {
  //     // Clear previous error message
  //     setErrors((prevErrors) => ({ ...prevErrors, image: '' }));

  //     // Read file for preview
  //     // Read the file for preview
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setImagePreview(e.target.result); // Set preview for the image
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         images: file, // Set the selected file as 'images' for main image
  //       }));
  //       setImagePreview(e.target.result); // Set the preview for the image
  //     };
  //     reader.readAsDataURL(file); // Read the image file
  //     // Call the handleUploadImage function to compress and set form data
  //     try {
  //       await handleProductImageUpload(
  //         event,
  //         setErrors,
  //         setFormData,
  //         setImagePreview,
  //       );
  //     } catch (error) {
  //       console.error('Error uploading image:', error);
  //     }
  //   }
  // };

  const handleOptionImageUpload = (event, index) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith('image/');
      const isSizeValid = file.size <= 5 * 1024 * 1024; // 5MB

      if (!isValidImage) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: 'Please upload a valid image file.',
        }));
        return;
      }

      if (!isSizeValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: 'File size should be less than 5MB.',
        }));
        return;
      }

      // Clear previous error message
      setErrors((prevErrors) => ({ ...prevErrors, image: '' }));

      const newOptions = [...options];
      newOptions[index].image = file;

      setOptions(newOptions);
      // console.log(newOptions);

      setFormData((prevData) => ({
        ...prevData,
        options: newOptions,
        images: file,
      }));
    }
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Name</FormLabel>
        <Input
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter product name"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Price</FormLabel>
        <Input
          name="price"
          type="number"
          value={formData.price}
          onChange={handleInputChange}
          placeholder="Enter product price"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Discount Percentage</FormLabel>
        <Input
          name="discountPercentage"
          type="number"
          value={formData.discountPercentage}
          onChange={handleInputChange}
          placeholder="Enter discount percentage"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Duration</FormLabel>
        <Flex>
          <Input
            name="durationValue"
            type="number"
            value={durationValue}
            onChange={handleDurationChange}
            placeholder="Enter duration"
            mr={2}
          />
          <Select
            name="durationUnit"
            value={durationUnit}
            onChange={handleDurationChange}
            width="100px"
          >
            <option value="min.">min.</option>
            <option value="hr.">hr.</option>
          </Select>
        </Flex>
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Details</FormLabel>
        <Textarea
          name="details"
          value={formData.details}
          onChange={handleInputChange}
          placeholder="Enter product details"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Upload Image</FormLabel>
        <Input
          name="image"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        />
        <FormErrorMessage>{errors.image}</FormErrorMessage>

        {imagePreview && (
          <Image src={imagePreview} alt="Product Image" mt={2} />
        )}
      </FormControl>

      <FormLabel>Options</FormLabel>
      {options.map((option, index) => (
        <VStack key={index} spacing={3} mb={4}>
          <HStack width="100%">
            <Input
              placeholder="Option Name"
              value={option.option}
              onChange={(e) =>
                handleOptionChange(index, 'option', e.target.value)
              }
            />
            <Input
              placeholder="Price"
              type="number"
              value={option.price}
              onChange={(e) =>
                handleOptionChange(index, 'price', e.target.value)
              }
            />
            <Button onClick={() => removeOption(index)} colorScheme="red">
              Remove
            </Button>
          </HStack>
          <Input
            placeholder="Option Details"
            value={option.details}
            onChange={(e) =>
              handleOptionChange(index, 'details', e.target.value)
            }
          />
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => handleOptionImageUpload(e, index)}
          />
          {option.image && (
            <Image src={option.image} alt="Option Image" mt={2} />
          )}
        </VStack>
      ))}
      <Button onClick={addOption} colorScheme="blue">
        Add Option
      </Button>
    </>
  );
};

export default EditCategoryProductDetails;
