import React from 'react';
import { Flex, Button, Box } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

/**
 * Pagination Component
 * @param {Object} props - The component props.
 * @param {number} props.currentPage - The current page number.
 * @param {number} props.totalPages - The total number of pages.
 * @param {Function} props.onPageChange - Function to call when the page changes.
 */
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  if (totalPages === 1) {
    // Only one page, so just show it
    pageNumbers.push(1);
  } else if (totalPages === 2) {
    // For 2 pages, show both pages
    pageNumbers.push(1);
    pageNumbers.push(2);
  } else if (totalPages === 3) {
    // For 3 pages, show "1 ... 3" or "1 2 3" if currentPage is 2
    pageNumbers.push(1);
    if (currentPage === 2) {
      pageNumbers.push(2);
    } else {
      pageNumbers.push('...');
    }
    pageNumbers.push(3);
  } else {
    // Handle pagination for more than 3 pages
    pageNumbers.push(1);

    if (currentPage > 2) {
      pageNumbers.push('...');
    }

    if (currentPage > 1 && currentPage < totalPages) {
      pageNumbers.push(currentPage);
    }

    if (currentPage < totalPages - 1) {
      pageNumbers.push('...');
    }

    pageNumbers.push(totalPages);
  }

  return (
    <Flex justify="end" align="center">
      <Button
        colorScheme="blue"
        size="sm"
        leftIcon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            left="3.5px" // Adjust this value to move the icon left
          >
            <ChevronLeftIcon />
          </Box>
        }
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        aria-label="Previous Page"
        mr={2}
        p={1}
      />
      {pageNumbers.map((number, index) => (
        <Button
          colorScheme="blue"
          size="sm"
          key={index}
          onClick={() => typeof number === 'number' && onPageChange(number)}
          isDisabled={number === '...' || number === currentPage}
          variant={number === currentPage ? 'solid' : 'outline'}
          mx={1}
          aria-label={`Page ${number}`}
        >
          {number}
        </Button>
      ))}
      <Button
        colorScheme="blue"
        size="sm"
        rightIcon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            right="3.5px" // Adjust this value to move the icon left
          >
            <ChevronRightIcon />
          </Box>
        }
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
        aria-label="Next Page"
        ml={2}
        p={1}
      />
    </Flex>
  );
};

export default Pagination;
