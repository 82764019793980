import React from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';

const EditMembershipPlanDetails = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Tenure</FormLabel>
        <Input
          name="tenure"
          type="number"
          value={formData.tenure || ''}
          onChange={handleInputChange}
          placeholder="Enter tenure value"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Tenure Type</FormLabel>
        <Select
          name="tenureType"
          value={formData.tenureType || 'month'}
          onChange={handleInputChange}
        >
          <option value="month">Months</option>
          <option value="week">Weeks</option>
          <option value="year">Year</option>
        </Select>
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Price</FormLabel>
        <Input
          name="price"
          type="number"
          value={formData.price}
          onChange={handleInputChange}
          placeholder="Enter price"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Discounted Price</FormLabel>
        <Input
          name="discountedPrice"
          type="number"
          value={formData.discountedPrice}
          onChange={handleInputChange}
          placeholder="Enter discounted price"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Discount Percentage</FormLabel>
        <Input
          name="discountPercentage"
          type="number"
          value={formData.discountPercentage}
          onChange={handleInputChange}
          placeholder="Enter discounted percentage"
        />
      </FormControl>
    </>
  );
};

export default EditMembershipPlanDetails;
