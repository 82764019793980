export const handleImageUpload = async (
  event,
  setErrors,
  setFormData,
  setImagePreview,
) => {
  const file = event.target.files[0];

  if (file) {
    // Check if it's an image
    if (!file.type.startsWith('image/')) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'Please upload an image file.',
      }));
      return;
    }

    // Compress the image if the file size is greater than 300KB
    if (file.size > 300 * 1024) {
      try {
        const compressedFile = await compressImage(file, 300 * 1024); // Target 300KB
        // console.log('Compressed File:', compressedFile);

        setFormData((prevData) => ({ ...prevData, image: compressedFile }));
        setErrors((prevErrors) => ({ ...prevErrors, image: '' }));

        // Set image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        // console.error('Error compressing image:', error);
      }
    } else {
      // If the image size is already less than 300KB
      setFormData((prevData) => ({ ...prevData, image: file }));
      setErrors((prevErrors) => ({ ...prevErrors, image: '' }));

      // Set image preview
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
};

export const handleProductImageUpload = async (
  event,
  setErrors,
  setFormData,
  setImagePreview,
) => {
  const file = event.target.files[0];

  if (file) {
    // Check if it's an image
    if (!file.type.startsWith('image/')) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'Please upload an image file.',
      }));
      return;
    }

    // Compress the image if the file size is greater than 300KB
    if (file.size > 300 * 1024) {
      try {
        const compressedFile = await compressImage(file, 300 * 1024); // Target 300KB
        // console.log('Compressed File:', compressedFile);

        setFormData((prevData) => ({ ...prevData, images: compressedFile }));
        setErrors((prevErrors) => ({ ...prevErrors, images: '' }));

        // Set image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    } else {
      // If the image size is already less than 300KB
      setFormData((prevData) => ({ ...prevData, images: file }));
      setErrors((prevErrors) => ({ ...prevErrors, images: '' }));

      // Set image preview
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
};

// Helper function to compress the image
const compressImage = (file, targetSize = 300 * 1024) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Initial dimensions
        let width = img.width;
        let height = img.height;

        // Resize the image if it's too large
        const MAX_WIDTH = 1000; // You can adjust this to your needs
        const MAX_HEIGHT = 1000; // You can adjust this to your needs
        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          if (width > height) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          } else {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        // Recursive compression function
        const compressToTargetSize = (currentQuality) => {
          return new Promise((resolveCompress) => {
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  // If compressed file is under the target size, resolve it
                  if (blob.size <= targetSize || currentQuality < 0.1) {
                    const compressedFile = new File([blob], file.name, {
                      type: file.type,
                      lastModified: Date.now(),
                    });
                    resolveCompress(compressedFile);
                  } else {
                    // If the file is still larger, reduce the quality and try again
                    const nextQuality = currentQuality - 0.05;
                    compressToTargetSize(nextQuality).then(resolveCompress);
                  }
                } else {
                  reject(new Error('Image compression failed.'));
                }
              },
              file.type,
              currentQuality, // Use calculated quality
            );
          });
        };

        // Start compression at a quality of 0.8 and reduce if necessary
        compressToTargetSize(0.8)
          .then((compressedFile) => resolve(compressedFile))
          .catch((err) => reject(err));
      };
    };

    reader.onerror = (error) => reject(error);
  });
};
