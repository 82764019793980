import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const useMembershipPlan = () => {
  const [membershipPlan, setMembershipPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [registeredMembershipPlan, setResgisterMembershipPlan] = useState(null);
  const toast = useToast({ position: 'top' });

  const fetchMembershipPlan = useCallback(async (page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/membership/fetch/all?page=${page}`,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );
      // console.log(response);

      if (response.data.success) {
        setMembershipPlan({
          data: response.data.data,
          totalPages: response.data.totalPages,
          currentPage: page,
        });
      } else {
        setError(response.data.message || 'Failed to fetch membership plan');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const registerMembershipPlan = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/membership/create`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const newPlan = response.data.data;
        setResgisterMembershipPlan(newPlan);

        toast({
          title: 'Success',
          description: 'New membership plan added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new membership plan');
        toast({
          title: 'Error',
          description:
            response.data.message || 'Failed to add new membership plan',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError(
        'An error occurred while adding the membership plan. Please try again.',
      );
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the membership plan. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredMembershipPlan,
    registerMembershipPlan,
    fetchMembershipPlan,
    membershipPlan,
    isLoading,
    error,
  };
};

export default useMembershipPlan;
