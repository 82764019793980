// Chakra imports
import React from 'react';
import AddNewSlots from './components/AddSlot';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import DevelopmentTable from 'views/admin/slotsTable/components/SlotsTable';

export default function Settings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Chakra Color Mode
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" color="white" h="100%" gap="4">
        <AddNewSlots onOpen={onOpen} />
        <DevelopmentTable isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Box>
  );
}
