import { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';

const useMembershipPlanActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({ position: 'top' });
  const { token } = useAuth();

  const updateMembershipPlanStatus = async (membershipId, newStatus) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/membership/change-status/?id=${membershipId}`,
        { isActive: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Status Updated',
          description: 'Membership plan status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update membership plan status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: 'Update Failed',
        description:
          error.message || 'Failed to update membership plan status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMembershipPlan = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/membership/delete/?id=${id}`,
        { isDeleted: true },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      // console.log(response.data);

      if (response.data.success) {
        toast({
          title: 'Membership plan Deleted',
          description:
            response.data.message ||
            'Membership plan has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          // title:
          //   'Account is already suspended, kindly contact the support team',
          description:
            response.data.message ||
            'Membership plan has been deleted successfully.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting Membership plan:', error);
      toast({
        title: 'Delete Failed',
        description: error.message || 'Failed to delete membership plan.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const updateMembershipPlanDetails = async (membershipId, updatedData) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/membership/update/?id=${membershipId}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Membership plan Updated',
          description:
            'Membership plan details have been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message ||
            'Failed to update Membership plan details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating Membership plan:', error);
      toast({
        title: 'Update Failed',
        description:
          error.message || 'Failed to update Membership plan details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateMembershipPlanStatus,
    updateMembershipPlanDetails,
    deleteMembershipPlan,
    isLoading,
  };
};

export default useMembershipPlanActions;
