import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Text,
} from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';

const ReusableTable = ({
  table,
  data,
  borderColor = 'gray.200',
  noDataText = 'No data available.',
  bgColor = 'aliceblue',
  isRowDeleted = (row) => row.original.isDeleted,
  rowBgColor = 'gray.200',
  rowTextColor = 'gray.600',
}) => {
  // console.log(data);

  // Check if data is an array and has a message property indicating no data found
  const noDataFound =
    Array.isArray(data) &&
    data.length === 0 &&
    data.message === 'No data found';

  return (
    <Box>
      <Table variant="simple" color="gray.500" mt="0px">
        <Thead position="sticky" top={0} left={0} bg={bgColor} zIndex="999">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  pe="10px"
                  borderColor={borderColor}
                  cursor="pointer"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <Flex
                    justifyContent="space-between"
                    align="center"
                    fontSize={{ sm: '11px', lg: '13px' }}
                    color="gray.400"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: '',
                      desc: '',
                    }[header.column.getIsSorted()] ?? null}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {data.length === 0 || noDataFound ? (
          <Flex align="center" color="black" fontWeight="600" m="1.5rem">
            <Text>{noDataFound ? data.message : noDataText}</Text>
          </Flex>
        ) : (
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              const isDeleted = isRowDeleted(row);
              return (
                <Tr
                  key={row.id}
                  backgroundColor={isDeleted ? rowBgColor : 'transparent'}
                  color={isDeleted ? rowTextColor : 'inherit'}
                  pointerEvents={isDeleted ? 'none' : 'auto'}
                  cursor={isDeleted ? 'not-allowed' : 'auto'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
    </Box>
  );
};

export default ReusableTable;
