import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

const SESSION_DURATION = 15 * 60 * 1000; // 15 MINUTES

const getUserRoles = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.role?.split(',') || [];
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const checkSession = useCallback(() => {
    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity) {
      const now = Date.now();
      if (now - parseInt(lastActivity) > SESSION_DURATION) {
        logout();
        navigate('/admin/sign-in');
      }
    }
  }, [navigate]);

  useEffect(() => {
    checkSession();
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      setUser(JSON.parse(loggedInUser));
      const userRoles = getUserRoles();
      setRoles(userRoles);
    }
    setLoading(false);
  }, [checkSession]);

  const login = useCallback((userData) => {
    setUser(userData);
    setToken(userData.token);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('lastActivity', Date.now().toString());
    localStorage.setItem('authToken', userData.token);

    const userRoles = getUserRoles();
    setRoles(userRoles);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    setRoles([]);
    localStorage.removeItem('user');
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('authToken');
  }, []);

  const updateLastActivity = useCallback(() => {
    localStorage.setItem('lastActivity', Date.now().toString());
  }, []);

  useEffect(() => {
    if (!user) return;

    const handleActivity = () => updateLastActivity();
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    const intervalId = setInterval(checkSession, 1000);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      clearInterval(intervalId);
    };
  }, [user, updateLastActivity, checkSession]);

  const value = useMemo(
    () => ({ user, token, roles, login, logout, loading }),
    [user, token, roles, login, logout, loading],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

// useEffect(() => {
//   //Check if user is logged in
//   const loggedInUser = localStorage.getItem('user');
//   if (loggedInUser) {
//     setUser(JSON.parse(loggedInUser));
//   }
//   setLoading(false);
// }, []);

// const login = (userData) => {
//   setUser(userData);
//   localStorage.setItem('user', JSON.stringify(userData));
//   resetSessionTimeout();
// };

// const logout = useCallback(() => {
//   setUser(null);
//   localStorage.removeItem('user');
//   clearSessionTimeout();
// }, []);

// const resetSessionTimeout = useCallback(() => {
//   clearSessionTimeout();
//   const timeout = setTimeout(() => {
//     logout();
//   }, SESSION_DURATION); // 15 minutes
//   localStorage.setItem('sessionTimeout', timeout);
// }, [logout]);

// const clearSessionTimeout = () => {
//   const timeoutId = localStorage.getItem('sessionTimeout');
//   if (timeoutId) {
//     clearTimeout(parseInt(timeoutId));
//     localStorage.removeItem('sessionTimeout');
//   }
// };

// useEffect(() => {
//   window.addEventListener('mousemove', resetSessionTimeout);
//   window.removeEventListener('keypress', resetSessionTimeout);

//   return () => {
//     window.addEventListener('mousemove', resetSessionTimeout);
//     window.removeEventListener('keypress', resetSessionTimeout);
//   };
// }, [resetSessionTimeout]);
