// Chakra imports
import { Box, Flex, IconButton } from '@chakra-ui/react';
import DevelopmentTable from 'views/admin/bookingTable/components/BookingTable';
import { columnsDataDevelopment } from 'views/admin/bookingTable/variables/columnsData';
import React from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import useBooking from 'hooks/bookingApis/useBooking';

export default function Settings() {
  const { downloadExcel } = useBooking();
  // Chakra Color Mode
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {/* <Flex margin="1.5rem"></Flex> */}
      <Flex direction="column" color="white" h="100%" gap="4">
        <Box display="flex" justifyContent="end">
          <IconButton
            aria-label="Download Booking Data"
            icon={<DownloadIcon />}
            colorScheme="blue"
            onClick={downloadExcel}
          />
        </Box>
        <DevelopmentTable columnsData={columnsDataDevelopment} />
      </Flex>
    </Box>
  );
}
