import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast({ position: 'top' });
  const { login } = useAuth();

  const loginUser = async (username, password) => {
    setIsLoading(true);
    try {
      // console.log(process.env.REACT_APP_BASE_URL);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/login`,
        { username, password },
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const token = response.data.data.token;

        localStorage.setItem('authToken', token);
        login({
          username: response.data.data.username,
          name: response.data.data.name,
          id: response.data.data.id,
          role: response.data.data.role,
          token: token,
        });
        navigate('/admin');
        toast({
          title: 'Login Successfully',
          description: response.data.message || 'Admin logged in successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Login Failed',
          description: response.data.message || 'Invalid username or password',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: 'Login Error',
        description: 'An error occurred during login. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { loginUser, isLoading };
};
