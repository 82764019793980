import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdHome, MdPerson } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import AdminTable from 'views/admin/adminTable';
import CustomerTable from 'views/admin/customerTable';
import PartnerTable from 'views/admin/partnerTable';
import StocksTable from 'views/admin/stocksTable';
import CatagoryTable from 'views/admin/catagoryTable';
import BannerTable from 'views/admin/bannerTable';
import ProductTable from 'views/admin/productTable';
// import PackageTable from 'views/admin/packageTable';
import MembershipTable from 'views/admin/membershipTable';
import FeedbackTable from 'views/admin/feedbackTable';
import ComplaintTable from 'views/admin/complainTable';
import NotificationTable from 'views/admin/notificationTable';
import OfferTable from 'views/admin/offerTable';
import SlotTable from 'views/admin/slotsTable';
import BookingTable from 'views/admin/bookingTable';
import StockAssignmentTable from 'views/admin/stockAssignmentTable';

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
    roles: ['all'],
  },
  {
    name: 'Admin',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/admin-table',
    component: <AdminTable />,
    roles: ['all', 'admin_manager'],
  },
  {
    name: 'Customer',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/customer',
    component: <CustomerTable />,
    roles: ['all', 'customer_manager'], // Add roles
  },
  {
    name: 'Partner',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/partner',
    component: <PartnerTable />,
    roles: ['all', 'partner_manager'], // Add roles
  },
  {
    name: 'Stock',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/stock',
    component: <StocksTable />,
    roles: ['all', 'stock_manager'],
  },
  // {
  //   name: 'Partner-Stocks',
  //   layout: '/admin',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   path: '/assign-stock',
  //   component: <StockAssignmentTable />,
  //   roles: ['all', 'stock_assignment_manager'],
  // },
  {
    name: 'Category',
    layout: '/admin',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    path: '/category',
    component: <CatagoryTable />,
    roles: ['all', 'category_manager'],
  },
  {
    name: 'Banner',
    layout: '/admin',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    path: '/banner',
    component: <BannerTable />,
    roles: ['all', 'banner_manager'],
  },
  {
    name: 'Products',
    layout: '/admin',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    path: '/products',
    component: <ProductTable />,
    roles: ['all', 'product_manager'],
  },
  // {
  //   name: 'Packages',
  //   layout: '/admin',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   path: '/package',
  //   component: <PackageTable />,
  // },
  {
    name: 'Membership',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/membership',
    component: <MembershipTable />,
    roles: ['all', 'membership_manager'],
  },
  {
    name: 'Feedback',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/feedback',
    component: <FeedbackTable />,
    roles: ['all', 'feedback_manager'],
  },
  {
    name: 'Complaint',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/complaints',
    component: <ComplaintTable />,
    roles: ['all', 'complaint_manager'],
  },
  {
    name: 'Notifications',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/notification',
    component: <NotificationTable />,
    roles: ['all', 'notification_manager'],
  },
  {
    name: 'Offers',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/offers',
    component: <OfferTable />,
    roles: ['all', 'offer_manager'],
  },
  {
    name: 'Slots',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/slots',
    component: <SlotTable />,
    roles: ['all', 'slot_manager'],
  },
  {
    name: 'Booking',
    layout: '/admin',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: '/booking',
    component: <BookingTable />,
    roles: ['all', 'booking_manager'],
  },
];

export default routes;
