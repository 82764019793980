'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
  FormControl,
  Switch,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import useAdmin from 'hooks/adminApis/useAdmin';
import usePagination from 'hooks/usePagination';
import convertDateFormat from 'utils/extractDate';
import useComplain from 'hooks/complaintApis/useComplain';
import ReusableTable from 'components/reusableTable/ReusableTable';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import Pagination from '../../../../components/pagination/Pagination';
import ResolveComplaint from 'components/popups/complaintPopup/ResolveComplain';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import { useSearch } from 'contexts/SearchContext';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable() {
  const toast = useToast({ position: 'top' });
  const { complain, isLoading, error, fetchComplain, resolveComplain } =
    useComplain();
  const { admins } = useAdmin();
  const { searchResults, searchQuery } = useSearch();
  // console.log(searchResults);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : complain.totalPages || 1;
  const { currentPage, handlePageChange } = usePagination(totalPages);

  React.useEffect(() => {
    if (!searchQuery) {
      fetchComplain(currentPage);
    }
  }, [fetchComplain, currentPage, searchQuery]);

  const handleComplain = async (complaintId, userData) => {
    try {
      await resolveComplain(complaintId, userData);
      fetchComplain(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return complain.data || [];
  }, [complain.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text width="35px" color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: ({ row }) => {
        const customerName = row.original.customerId?.name;

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              width="100px"
            >
              <GenericDetailView
                type="text"
                content={customerName || 'NA'}
                title="Full Name"
                maxChars={10}
                truncateType="chars"
              />
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          EMAIL
        </Text>
      ),
      cell: ({ row }) => {
        const email = row.original.customerId?.email || 'NA';
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {email}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('number', {
      id: 'number',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NUMBER
        </Text>
      ),
      cell: ({ row }) => {
        const number = row.original.customerId?.mobile || 'NA';
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {number}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('_id', {
      id: '_id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          COMPLAIN ID
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          DESCRIPTION
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text width="170px" color={textColor} fontSize="sm" fontWeight="700">
            {/* <DetailViewPopup title={'Description'} content={info.getValue()} /> */}
            <GenericDetailView
              type="text"
              content={info.getValue() || 'NA'}
              title="Description"
              maxChars={20}
              truncateType="chars"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('complaintCategory', {
      id: 'complaintCategory',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          CATEGORY
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          COM. DATE
        </Text>
      ),
      cell: (info) => {
        const originalDate = info.getValue();
        const formattedDate = convertDateFormat(originalDate);
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700" width="90px">
            {formattedDate || 'NA'}
          </Text>
        );
      },
    }),
    columnHelper.accessor('closingRemark', {
      id: 'closingRemark',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          CLOSING REMARK
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text width="150px" color={textColor} fontSize="sm" fontWeight="700">
            <GenericDetailView
              type="text"
              content={info.getValue() || 'NA'}
              title="Closing Remark"
              maxChars={20}
              truncateType="chars"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('resolved', {
      id: 'resolved',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <FormControl display="flex" alignItems="center">
            <Switch
              size="10px"
              isChecked={row.original.resolved}
              isDisabled={isLoading}
            />
            <Text ml={3} fontWeight="medium" fontSize="sm">
              {row.original.resolved ? 'Resolved' : 'Not Resolved'}
            </Text>
          </FormControl>
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => {
        return (
          <Flex align="center">
            <EditButton
              entityType="complain"
              EditComponent={ResolveComplaint}
              onConfirm={(updatedData) =>
                handleComplain(row.original._id, updatedData)
              }
              admins={admins}
            />
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading)
    return (
      <Flex align="center" justify="center" h="60vh">
        <Spinner speed="0.65s" color="blue.500" size="lg" />
      </Flex>
    );
  if (error)
    return toast({
      title: 'Error',
      description: complain.message || 'Failed to fetch complain.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
      </Card>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
}
