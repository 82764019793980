// Chakra imports
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import DevelopmentTable from 'views/admin/complainTable/components/ComplainTable';

export default function Settings() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex margin="1.5rem"></Flex>
      <Flex direction="column" color="white" h="100%" gap="4">
        <DevelopmentTable />
      </Flex>
    </Box>
  );
}
