import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';

const EditPartnerDetails = ({ formData, setFormData }) => {
  const [pincodeInput, setPincodeInput] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [pincode, setPincodes] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // Convert the comma-separated string to an array
    if (formData.pincode) {
      setPincodes(formData.pincode.split(','));
    }
    if (formData.image && typeof formData.image === 'string') {
      setImagePreview(formData.image);
    }
  }, [formData.pincode, formData.image]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePincodeChange = (e) => {
    setPincodeInput(e.target.value);
  };

  const handleAddPincode = () => {
    const trimmedPincode = pincodeInput.trim();
    if (trimmedPincode && !pincode.includes(trimmedPincode)) {
      const updatedPincodes = [...pincode, trimmedPincode];
      setPincodes(updatedPincodes);
      setPincodeInput('');
      // Update formData with the new comma-separated string
      setFormData((prevData) => ({
        ...prevData,
        pincode: updatedPincodes.join(','),
      }));
    }
  };

  const handleRemovePincode = (pincodeToRemove) => {
    const updatedPincodes = pincode.filter(
      (pincode) => pincode !== pincodeToRemove,
    );
    setPincodes(updatedPincodes);
    // Update formData with the new comma-separated string
    setFormData((prevData) => ({
      ...prevData,
      pincode: updatedPincodes.join(','),
    }));
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Name</FormLabel>
        <Input
          name="name"
          value={formData.name || ''}
          onChange={handleInputChange}
          placeholder="Enter name"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Email</FormLabel>
        <Input
          name="email"
          type="email"
          value={formData.email || ''}
          onChange={handleInputChange}
          placeholder="Enter email"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Phone</FormLabel>
        <Input
          name="phone"
          type="tel"
          value={formData.phone || ''}
          onChange={handleInputChange}
          placeholder="Enter phone"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Address</FormLabel>
        <Textarea
          name="address"
          value={formData.address || ''}
          onChange={handleInputChange}
          placeholder="Enter address"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>Upload Image</FormLabel>
        <Input
          name="image"
          type="file"
          accept="image/*"
          onChange={(e) =>
            handleImageUpload(e, setErrors, setFormData, setImagePreview)
          }
        />
        <FormErrorMessage>{errors.image}</FormErrorMessage>
      </FormControl>
      {imagePreview && (
        <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
      )}
      <FormControl mb={3}>
        <FormLabel>Pincodes</FormLabel>
        <Box display="flex" mb={2}>
          <Input
            value={pincodeInput}
            onChange={handlePincodeChange}
            placeholder="Enter pincode"
          />
          <Button ml={2} onClick={handleAddPincode}>
            Add
          </Button>
        </Box>
        <Box>
          {pincode.length > 0 ? (
            pincode.map((pincode, index) => (
              <Tag
                key={index}
                size="md"
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
                mb={1}
                mr={1}
              >
                <TagLabel>{pincode}</TagLabel>
                <TagCloseButton onClick={() => handleRemovePincode(pincode)} />
              </Tag>
            ))
          ) : (
            <Text>No pincode added yet.</Text>
          )}
        </Box>
      </FormControl>
    </>
  );
};

export default EditPartnerDetails;
