import React, { useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
  Image,
  Text,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { handleImageUpload } from 'utils/handleImageUpload';

const EditNotificationDetails = ({ formData, setFormData }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setFormData((prev) => ({ ...prev, notificationDate: formattedDate }));
  };

  const convertTo12HourFormat = (time24h) => {
    const [hours, minutes] = time24h.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${String(hours12).padStart(2, '0')}:${minutes} ${period}`;
  };

  const handleTimeChange = (e) => {
    const time = e.target.value; // Get time in 24-hour format (HH:mm)

    // Create a new Date object using the selected date or today's date
    const selectedDate = formData.notificationDate
      ? new Date(`${formData.notificationDate}T${time}`)
      : new Date();

    // Set the selected time on the selectedDate
    const [hours, minutes] = time.split(':');
    selectedDate.setHours(hours);
    selectedDate.setMinutes(minutes);

    // Check if the selected time is in the future
    if (selectedDate <= new Date()) {
      setErrors((prev) => ({
        ...prev,
        notificationTime: 'Time must be in the future',
      }));
    } else {
      setErrors((prev) => ({ ...prev, notificationTime: '' }));

      // Convert time to 12-hour format (hh:mm A) and store it
      const formattedTime = convertTo12HourFormat(time);
      setFormData((prev) => ({
        ...prev,
        notificationTime: formattedTime, // Save the time in "hh:mm A" format
      }));
    }
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Notification Title</FormLabel>
        <Input
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          placeholder="Enter notification title"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Notification Body</FormLabel>
        <Textarea
          name="body"
          value={formData.body}
          onChange={handleInputChange}
          placeholder="Enter notification body"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Notification Date</FormLabel>
        <DatePicker
          selected={
            formData.notificationDate
              ? new Date(formData.notificationDate)
              : null
          }
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          minDate={new Date()} // Disable past dates
        />
      </FormControl>

      <FormControl mb={3} isInvalid={errors.notificationTime}>
        <FormLabel>Notification Time</FormLabel>
        <Input
          name="notificationTime"
          type="time"
          onChange={handleTimeChange}
          value={
            formData.notificationTime
              ? convertTo12HourFormat(formData.notificationTime)
              : ''
          } // For editing, convert back to 12-hour format
        />
        {errors.notificationTime && (
          <FormErrorMessage>{errors.notificationTime}</FormErrorMessage>
        )}
      </FormControl>

      {formData.notificationTime && (
        <Text color="blue.500" fontSize="md">
          Selected Time: {formData.notificationTime}
        </Text>
      )}

      <FormControl mb={3}>
        <FormLabel>Upload Image</FormLabel>
        <Input
          name="image"
          type="file"
          accept="image/*"
          onChange={(e) =>
            handleImageUpload(e, setErrors, setFormData, setImagePreview)
          }
        />
        <FormErrorMessage>{errors.image}</FormErrorMessage>
      </FormControl>
      {imagePreview && (
        <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
      )}
    </>
  );
};

export default EditNotificationDetails;
