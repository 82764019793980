import React, { useState, useEffect } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Select,
  Box,
  Text,
} from '@chakra-ui/react';

const EditSlotDetails = ({ formData, setFormData }) => {
  const [startTime, setStartTime] = useState('');
  const [clockCycle, setClockCycle] = useState('AM');

  useEffect(() => {
    if (formData.startTime) {
      setStartTime(formData.startTime);
    }
    if (formData.clockCycle) {
      setClockCycle(formData.clockCycle);
    }
  }, [formData.startTime, formData.clockCycle]);

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === 'startTime') {
      setStartTime(value);
    } else if (name === 'clockCycle') {
      setClockCycle(value);
    }
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Start Time</FormLabel>
        <Input
          name="startTime"
          type="time"
          value={startTime}
          onChange={handleTimeChange}
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Clock Cycle</FormLabel>
        <Select
          name="clockCycle"
          value={clockCycle}
          onChange={handleTimeChange}
          placeholder="Select clock cycle"
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </Select>
      </FormControl>

      {startTime && (
        <Box mt={3}>
          <Text fontWeight="bold">Current Slot Details:</Text>
          <ul>
            <li>
              Start Time: {startTime} {clockCycle}
            </li>
          </ul>
        </Box>
      )}
    </>
  );
};

export default EditSlotDetails;
