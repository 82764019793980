// Chakra imports
import React from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import AddNewAdmin from './components/AddNewAdmin';
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import DevelopmentTable from 'views/admin/adminTable/components/AdminTable';
import useAdminActions from 'hooks/adminApis/useAdminActions';

export default function Settings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { downloadExcel } = useAdminActions();

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" color="white" h="100%" gap="4">
        <Flex justifyContent="flex-end" gap="4">
          <AddNewAdmin onOpen={onOpen} />
          <IconButton
            aria-label="Download Admin Data"
            icon={<DownloadIcon />}
            colorScheme="blue"
            onClick={downloadExcel}
          />
        </Flex>
        <DevelopmentTable isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Box>
  );
}
