import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Flex,
  FormErrorMessage,
  useDisclosure,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useLogin } from 'hooks/adminApis/useAdminLogin';
import ForgotPasswordPopup from 'components/popups/adminPopup/ForgotPasswordPopup';

const AdminLogin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loginUser, isLoading } = useLogin();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username.trim()) newErrors.username = 'Username is required';
    if (!formData.password.trim()) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await loginUser(formData.username, formData.password);
    }
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
      <Box width="400px" borderWidth={1} borderRadius={8} boxShadow="lg" p={8}>
        <VStack spacing={4} align="stretch">
          <Heading textAlign="center">Admin Login</Heading>
          <form onSubmit={handleLogin}>
            <FormControl isInvalid={errors.username}>
              <FormLabel>Username</FormLabel>
              <Input
                name="username"
                type="text"
                value={formData.username}
                onChange={handleInputChange}
              />
              <FormErrorMessage>{errors.username}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password} mt={4}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={toggleShowPassword}>
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              width="full"
              mt={4}
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </Button>
          </form>
        </VStack>
        <Button variant="link" onClick={onOpen}>
          Forgot Password?
        </Button>
      </Box>

      {/* Include ForgotPasswordPopup here */}
      <ForgotPasswordPopup isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default AdminLogin;
