import React, { useEffect, useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Image,
  FormErrorMessage,
  Flex,
  Select,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';

const EditStockDetails = ({ formData, setFormData }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (formData.image && typeof formData.image === 'string') {
      setImagePreview(formData.image);
    }
  }, [formData]);

  // Handle the numeric part of the size input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle the unit part of the size input
  const handleSizeUnitChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      size: `${prevData.size.split(' ')[0]} ${value}`, // Combine the numeric part and the unit
      sizeUnit: value, // Keep track of the unit separately
    }));
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Name</FormLabel>
        <Input
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter stock name"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Brand</FormLabel>
        <Input
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
          placeholder="Enter brand"
        />
      </FormControl>

      <FormControl isInvalid={errors.size} mb={3}>
        <FormLabel>Product Size</FormLabel>
        <Flex align="center" gap={2}>
          <Input
            name="size"
            value={formData.size.split(' ')[0]} // Show only the numeric part
            onChange={handleInputChange}
            placeholder="Enter product size"
          />
          <Select
            name="sizeUnit"
            value={formData.sizeUnit}
            onChange={handleSizeUnitChange}
          >
            <option value="ml">ml</option>
            <option value="pc">pc</option>
            <option value="gms">gms</option>
            <option value="kg">kg</option>
            <option value="time use">time use</option>
          </Select>
        </Flex>
        <FormErrorMessage>{errors.size}</FormErrorMessage>
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>MRP</FormLabel>
        <Input
          name="mrp"
          type="number"
          value={formData.mrp}
          onChange={handleInputChange}
          placeholder="Enter MRP"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Purchasing Rate</FormLabel>
        <Input
          name="purchasingRate"
          type="number"
          value={formData.purchasingRate}
          onChange={handleInputChange}
          placeholder="Enter purchasing rate"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Barcode Number</FormLabel>
        <Input
          name="barcodeNumber"
          value={formData.barcodeNumber}
          onChange={handleInputChange}
          placeholder="Enter barcode number"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Upload Image</FormLabel>
        <Input
          name="image"
          type="file"
          accept="image/*"
          onChange={(e) =>
            handleImageUpload(e, setErrors, setFormData, setImagePreview)
          }
        />
        <FormErrorMessage>{errors.image}</FormErrorMessage>
      </FormControl>

      {imagePreview && (
        <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
      )}
    </>
  );
};

export default EditStockDetails;
