export const columnsDataDevelopment = [
  {
    Header: 'SL. NO.',
    accessor: 'sl. no.',
  },
  {
    Header: 'PROFILE',
    accessor: 'profile',
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  {
    Header: 'MOBILE',
    accessor: 'mobile',
  },
  {
    Header: 'ADDRESS',
    accessor: 'address',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'ACTION',
    accessor: 'action',
  },
];
